import React, { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import Icon from "./icon";
import actions from "@/store/actions";
import { Steps, ServiceSubStep, Services } from "@/store/state";
import { useSnapshot } from "valtio";
import state from "@/store/state";
import { BasketSummaryStepper, TARGET_AMOUNT } from "./basket-summary-stepper";
import { useSearchParams } from "react-router-dom";
import { priceList } from "@/config";

interface BasketSummaryProps {
  itemCount: number;
  originalPrice: string;
  discountedPrice: string;
  onGoToPayment: () => void;
}

export function BasketSummary({
  itemCount,
  originalPrice,
  discountedPrice,
  onGoToPayment,
}: BasketSummaryProps) {
  const { currentStep, serviceSubStep } = useSnapshot(state);
  const [searchParams, setSearchParams] = useSearchParams();
  const [hideStepperCompletely, setHideStepperCompletely] = useState(false);

  const getTotalAmount = () => {
    return parseFloat(discountedPrice.replace(/[^0-9.-]+/g, ""));
  };

  useEffect(() => {
    const totalAmount = getTotalAmount();
    if (totalAmount < 10 && hideStepperCompletely) {
      setHideStepperCompletely(false);
    }
  }, [discountedPrice, hideStepperCompletely]);

  const shouldShowStepper = () => {
    if (hideStepperCompletely) return false;

    const hasGoogleAdsUtmSource = searchParams.get("utm_source") === "google-ads";
    let shouldShow = sessionStorage.getItem("showStepper");
    if (shouldShow === null) {
      shouldShow = Math.random() < 0.5 ? "true" : "false";
      sessionStorage.setItem("showStepper", shouldShow);
    }

    const { user, cart } = state;
    if (user && cart) {
      const userCart = cart.find((item) => item.username === user.username);
      if (userCart) {
        const hasHighPriceProduct = Object.entries(userCart.products).some(
          ([service, products]) => {
            return products.some((product) => {
              const packInfo = priceList[service as Services].find(
                (pack) => pack.id.toString() === product.service_id
              );
              if (packInfo) {
                return packInfo.price > TARGET_AMOUNT;
              }
              return false;
            });
          }
        );

        if (hasHighPriceProduct) return false;
      }
    }

    return (
      hasGoogleAdsUtmSource &&
      shouldShow === "true" &&
      (currentStep === Steps.CHOOSE_SERVICE ||
        (currentStep === Steps.CHECKOUT &&
          serviceSubStep === ServiceSubStep.BASKET))
    );
  };

  const handleStepperComplete = () => {
    setHideStepperCompletely(true);
  };

  const getStepperStep = () => {
    return 1;
  };

  const handleGoToPayment = () => {
    const newParams = new URLSearchParams(searchParams);
    if (currentStep === Steps.CHOOSE_SERVICE) {
      if (serviceSubStep === ServiceSubStep.SERVICE_SELECTION) {
        actions.setServiceSubStep(ServiceSubStep.BASKET);
        newParams.set("subStep", ServiceSubStep.BASKET);
        setSearchParams(newParams);
      } else {
        onGoToPayment();
      }
    }
  };

  const buttonText =
    serviceSubStep === ServiceSubStep.BASKET ? "Go to Payment" : "Go to Basket";

  const isCheckoutStep = currentStep === Steps.CHECKOUT;
  const hasDiscount = originalPrice !== discountedPrice;

  const calculateDiscountPercentage = () => {
    const original = parseFloat(originalPrice.replace(/[^0-9.-]+/g, ""));
    const discounted = parseFloat(discountedPrice.replace(/[^0-9.-]+/g, ""));
    const percentage = ((original - discounted) / original) * 100;
    return Math.round(percentage);
  };

  return (
    <div>
      {shouldShowStepper() && (
        <BasketSummaryStepper
          currentStep={getStepperStep()}
          totalAmount={getTotalAmount()}
          onComplete={handleStepperComplete}
        />
      )}
      <div className="flex items-center justify-between px-4 py-3">
        <div className="flex items-center">
          {!isCheckoutStep && (
            <div className="relative mr-3">
              <Icon
                icon="shopping-cart-2-fill"
                className="w-6 h-6 text-gray-900"
              />
              <span className="absolute -top-1 -right-1 border border-white bg-orange-300 text-white text-detail drop-shadow-md rounded-full w-4 h-4 flex items-center justify-center">
                {itemCount}
              </span>
            </div>
          )}
          <div className="flex items-center gap-2">
            {hasDiscount ? (
              <>
                <span className="text-body-xs-m text-gray-400 line-through">
                  {originalPrice}
                </span>
                <Separator orientation="vertical" className="h-5" />
                <span className="text-body-xs-m text-gray-900">
                  {discountedPrice}
                </span>
                <Separator orientation="vertical" className="h-5" />
                <span className="ml-auto px-[6px] py-[2px] h-5 flex justify-center mr-1 items-center text-center bg-green-300 border border-white text-white text-detail text-nowrap rounded-full shadow-[-3px_4px_4px_-2px_rgba(19,19,19,0.20)]">
                  {calculateDiscountPercentage()}% OFF
                </span>
              </>
            ) : (
              <span className="text-body-xs-m text-gray-900">
                {originalPrice}
              </span>
            )}
          </div>
        </div>
        <Button onClick={handleGoToPayment}>{buttonText}</Button>
      </div>
    </div>
  );
}
