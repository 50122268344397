import ky from "ky";

const API_BASE_URL = "https://api.famepeak.io";

const api = ky.create({
  prefixUrl: API_BASE_URL,
  timeout: 30000,
  retry: {
    limit: 2,
    methods: ["get", "put", "head", "delete", "options", "trace"],
  },
  cache: "no-cache",
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;

export const getCouponCode = async (couponCode?: string) => {
  const url = new URL("https://api.famepeak.io/getCouponCodes");
  if (couponCode) {
    url.searchParams.append("coupon", couponCode);
  }

  const response = await ky
    .get(url.toString())
    .json<{ code: string; amount: string; type: string }[]>();
  return response;
};
