import React, { useEffect, useState } from "react";
import {
  ShoppingBag,
  Copy,
  Clock,
  Info,
  User,
  Heart,
  Eye,
  Share2,
} from "lucide-react";
import { type OrderStatus } from "@/store/state";
import Icon from "../icon";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Separator } from "../ui/separator";
import { useNotify } from "@/hooks/useNotify";
import confetti from "canvas-confetti";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import actions from "@/store/actions";
import { Steps } from "@/store/state";
import OrderDetails from "./order-details";

interface OrderStatusProps {
  orderStatus: OrderStatus | null;
}

interface StatusConfig {
  icon: string;
  bgColor: string;
  textColor: string;
  text: string;
  helperText: React.ReactNode;
  emoji: string;
}

const statusMapper: Record<string, StatusConfig> = {
  completed: {
    icon: "check-line",
    bgColor: "bg-green-300",
    textColor: "text-green-300",
    text: "Order Completed",
    helperText: (
      <span className="text-gray-400 text-body-2xs-r">
        Your order was completed on{" "}
        <strong className="text-gray-900 text-body-2xs-m">Sep 6, 2024</strong>.
      </span>
    ),
    emoji: "🚀",
  },
  "on-hold": {
    icon: "loader-line",
    bgColor: "bg-red-300",
    textColor: "text-red-300",
    text: "Order is on-hold",
    helperText: (
      <span className="text-gray-400 text-body-2xs-r">
        Your order will be processed within{" "}
        <strong className="text-gray-900 text-body-2xs-m">1-24 hours</strong>.
      </span>
    ),
    emoji: "⏳",
  },
  processing: {
    icon: "loader-line",
    bgColor: "bg-yellow-300",
    textColor: "text-yellow-300",
    text: "Order Processing",
    helperText: (
      <span className="text-gray-400 text-body-2xs-r">
        Your order will be processed within{" "}
        <strong className="text-gray-900 text-body-2xs-m">1-24 hours</strong>.
      </span>
    ),
    emoji: "⏳",
  },
  received: {
    icon: "shopping-bag-line",
    bgColor: "bg-[#375dfb]",
    textColor: "text-blue-300",
    text: "Order Received",
    helperText: (
      <span className="text-gray-400 text-body-2xs-r">
        Your order will be processed within{" "}
        <strong className="text-gray-900 text-body-2xs-m">1-24 hours</strong>.
      </span>
    ),
    emoji: "⏳",
  },
};

export default function OrderStatus({ orderStatus }: OrderStatusProps) {
  const { notify } = useNotify();
  const navigate = useNavigate();

  const handleCopy = () => {
    if (orderStatus?.orderId) {
      navigator.clipboard.writeText(orderStatus?.orderId);
      notify("Copied!", "Order number copied to clipboard", {
        variant: "green",
      });
    }
  };
  const status = orderStatus?.status || "received";
  const statusConfig = statusMapper[status];

  const isSuccess = status === "completed";

  useEffect(() => {
    sessionStorage.removeItem("showStepper");
  }, []);

  useEffect(() => {
    if (isSuccess) {
      const end = Date.now() + 2 * 1000; // 2 seconds
      const colors = ["#a786ff", "#fd8bbc", "#eca184", "#f8deb1"];

      const frame = () => {
        if (Date.now() > end) return;

        confetti({
          particleCount: 3,
          angle: 60,
          spread: 55,
          startVelocity: 50,
          origin: { x: 0, y: 0.5 },
          colors: colors,
        });
        confetti({
          particleCount: 3,
          angle: 120,
          spread: 55,
          startVelocity: 50,
          origin: { x: 1, y: 0.5 },
          colors: colors,
        });

        requestAnimationFrame(frame);
      };

      frame();
    }
  }, [isSuccess]);

  return (
    <div>
      <Card
        className="border border-gray-100 rounded-xl"
        style={{
          background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 100%), var(--White, #FFF)",
        }}
      >
        <CardContent className="px-6 py-4 relative">
          <div className="flex flex-col items-center gap-3 self-stretch rounded-xl bg-white/[.12]">
            <div
              className={`flex justify-center items-center p-1 w-10 h-10 rounded-full border-[2.5px] border-[#ffffff]/[.56] ${statusConfig.bgColor}`}
            >
              <Icon icon={statusConfig.icon} className="h-7 w-7 text-white" />
            </div>
            <div
              className={`self-stretch ${statusConfig.textColor} text-center text-body-2xs-m`}
            >
              {statusConfig.text}
            </div>
            <div
              className="flex justify-center items-center gap-3 py-1 px-3 rounded-[6px] border border-[#ffffff]/[.12]"
              style={{
                background:
                  "linear-gradient(180deg, rgba(255,255,255,0.12) 0%, rgba(255,255,255,0.00) 100%), #F4F4F5",
                boxShadow: "0px 1px 2px 0px var(--Gray-300, #D4D4D8)",
              }}
            >
              <div className="flex items-center gap-1 text-zinc-900 text-center text-xs font-medium">
                Order #{orderStatus?.orderId || "Unknown"}
              </div>
              <Separator className="h-[18px] w-[1px] !bg-[#E4E4E7]" />
              <Icon
                icon="file-copy-line"
                className="h-4 w-4 text-zinc-900 cursor-pointer"
                onClick={handleCopy}
              />
            </div>
            <div className="flex items-center gap-1">
              {statusConfig.helperText}
              {statusConfig.emoji}
            </div>
            <div className="flex justify-center items-center gap-1 px-2 py-1 pl-1.5 rounded-md border border-zinc-100 bg-neutral-50">
              <Icon icon="information-fill" className="h-4 w-4 text-gray-400" />
              <div className="text-zinc-400 text-center text-[10px]">
                We'll notify you when the order progresses.
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      <div className="mt-4">
        {orderStatus && <OrderDetails orderStatus={orderStatus} />}
      </div>

      <Card
        className="border border-gray-100 rounded-xl mt-4 py-3 px-5"
        style={{
          background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 100%), var(--White, #FFF)",
        }}
      >
        <CardHeader className="!text-body-xs-m p-0">Price Summary</CardHeader>
        <Separator className="h-[1px] w-full my-3 !bg-[#F4F4F5]" />
        <CardContent className="p-0">
          <div className="flex justify-between mb-2">
            <span className="text-gray-400 text-body-2xs-r">
              Service Total:
            </span>
            <span className="text-gray-400 text-body-2xs-r">
              ${orderStatus?.total || "0.00"}
            </span>
          </div>
          {/* <div className="flex justify-between mb-2">
            <span className="text-gray-400 text-body-2xs-r">Discount Applied:</span>
            <span className="text-gray-400 text-body-2xs-r">-$39.97</span> */}
          {/* </div> */}
          <div className="flex justify-between mt-4 pt-4 border-t border-gray-100">
            <span className="text-gray-900 text-body-2xs-m">Total</span>
            <span className="text-gray-900 text-body-2xs-m">
              ${orderStatus?.total || "0.00"}
            </span>
          </div>
        </CardContent>
      </Card>

      <div className="fixed bottom-0 left-0 right-0 max-w-2xl px-4 py-3 bg-white mx-auto border-t border-gray-100">
        <Button
          onClick={() => {
            actions.resetCart(); // Sadece cart'ı resetle
            actions.updateCurrentStep(Steps.CHOOSE_SERVICE);
            navigate("/order");
          }}
          className="w-full"
        >
          Check Other TikTok Services
        </Button>
      </div>
    </div>
  );
}
