import { cn } from "@/lib/utils";
import confetti from "canvas-confetti";
import { useEffect, useRef } from "react";

interface BasketSummaryStepperProps {
  currentStep: number;
  totalAmount: number;
  onComplete?: () => void;
}

export const TARGET_AMOUNT = 15;
export const DISCOUNT_AMOUNT = 2.5;
export function BasketSummaryStepper({
  currentStep,
  totalAmount,
  onComplete,
}: BasketSummaryStepperProps) {
  const prevStepRef = useRef<number>(1);
  const containerRef = useRef<HTMLDivElement>(null);

  const calculateProgress = () => {
    const progress = (totalAmount / TARGET_AMOUNT) * 100;
    return Math.min(progress, 100);
  };

  const triggerConfetti = () => {
    if (!containerRef.current) return;

    const end = Date.now() + 2 * 1000;
    const colors = ["#a786ff", "#fd8bbc", "#eca184", "#f8deb1"];

    const rect = containerRef.current.getBoundingClientRect();
    const leftOrigin = {
      x: rect.left / window.innerWidth,
      y: rect.top / window.innerHeight,
    };
    const rightOrigin = {
      x: rect.right / window.innerWidth,
      y: rect.top / window.innerHeight,
    };

    const frame = () => {
      if (Date.now() > end) return;

      confetti({
        particleCount: 2,
        angle: 60,
        spread: 45,
        startVelocity: 30,
        scalar: 0.7,
        gravity: 0.8,
        drift: 0.1,
        ticks: 150,
        origin: leftOrigin,
        colors: colors,
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 45,
        startVelocity: 30,
        scalar: 0.7,
        gravity: 0.8,
        drift: 0.1,
        ticks: 150,
        origin: rightOrigin,
        colors: colors,
      });

      requestAnimationFrame(frame);
    };

    frame();
  };

  const getRemainingAmount = () => {
    const remaining = TARGET_AMOUNT - totalAmount;
    return Math.max(remaining, 0).toFixed(2);
  };

  const steps = [
    {
      id: 1,
      message: `Add $${getRemainingAmount()} more to your basket, save $${DISCOUNT_AMOUNT} now!`,
    },
    {
      id: 2,
      message: `Add $${getRemainingAmount()} more to your basket, save $${DISCOUNT_AMOUNT} now!`,
    },
    {
      id: 3,
      message: "You just saved $2.5 on your basket! 🎉",
    },
  ];

  const getCurrentStep = () => {
    const progress = calculateProgress();
    if (progress >= 100) return 3;
    if (progress >= 50) return 2;
    return 1;
  };

  useEffect(() => {
    const currentStep = getCurrentStep();
    if (currentStep === 3 && prevStepRef.current !== 3) {
      triggerConfetti();
      setTimeout(() => {
        onComplete?.();
      }, 5000);
    }
    prevStepRef.current = currentStep;
  }, [totalAmount, onComplete]);

  const currentStepData = steps.find((step) => step.id === getCurrentStep());

  if (!currentStepData) return null;

  const progress = calculateProgress();

  return (
    <div className="w-full" ref={containerRef}>
      <div
        className={cn(
          "w-full overflow-hidden transition-[height] duration-300 ease-in-out",
          currentStepData.id === 3 ? "h-0" : "h-1"
        )}
      >
        <div className="w-full h-1 relative">
          <div
            className={cn(
              "absolute top-0 left-0 h-full w-full",
              currentStepData.id === 3 ? "bg-white/20" : "bg-purple-200"
            )}
          />
          <div
            className={cn(
              "absolute top-0 left-0 h-full",
              currentStepData.id === 3 ? "bg-purple-200" : "bg-purple-300"
            )}
            style={{
              width: `${progress}%`,
              transition: "width 0.5s ease-in-out",
            }}
          />
        </div>
      </div>

      <div
        className={cn(
          "w-full h-8 flex items-center justify-center transition-colors duration-300 ease-in-out",
          currentStepData.id === 3
            ? "bg-purple-300 text-white"
            : "bg-purple-100 text-purple-600"
        )}
      >
        <p className="!text-body-2xs-m">{currentStepData.message}</p>
      </div>
    </div>
  );
}
